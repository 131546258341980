.TemplateTerms {
	.Title {
		color: var(--blue);
		font-size: 28px;
		font-weight: 600;
		line-height: 40px;
	}

	.Row {
		display: flex;
	}

	.ClauseList {
		display: flex;
		flex-direction: column;
		gap: 10px;
	}
}
.ClauseBtn {
	display: flex;
	gap: 10px;
	width: 250px;
}

.Modal{
    width: 600px;
    border-top: 1px solid var(--blue25);
    padding: 30px;
}
.Controls{
    display: flex;
    gap: 10px;
    justify-content: right;
    div{
        flex-basis: 150px;
    }
}
.Modal {
	width: 500px;
	height: 500px;
	padding: 30px 32px;
	border-top: 1px solid var(--blue25);
	display: flex;
	flex-direction: column;
	> .Foot {
		margin-top: auto;
		display: flex;
		justify-content: flex-end;
		> div {
			width: 150px;
		}
	}
}
.Items {
	display: flex;
	flex-direction: column;
	gap: 16px;
	margin-bottom: 16px;
	> :hover {
		background-color: var(--blue10);
	}
}
.FileInput {
	display: none;
}
.Delete {
	width: 0px;
	transform: translateX(-30px);
}
.Item {
	display: flex;
	gap: 16px;
	border: 2px solid var(--blue25);
	padding: 10px 0px 10px 10px;
	.Img {
		border-radius: 8px;
		width: 120px;
		height: 120px;
		background-color: var(--blue25);
		overflow: hidden;
		> label {
			display: block;
			height: 120px;
			width: 120px;
			> img {
				height: 120px;
			}
		}
	}
	.FormCont {
		flex-grow: 1;
		.Details {
			display: flex;
			justify-content: space-between;
		}
	}
}
.Value {
	display: flex;
	> div {
		width: 80px;
	}
	.Currency {
		background-color: var(--blue25);
		color: var(--blue);
		margin: 8px 0px;
		line-height: 200%;
		padding-left: 10px;
		width: 50px;
	}
}
.Submit {
	margin-bottom: 10px;
}
.pdf {
	object {
		pointer-events: none;
	}
}
.UploadTypeName {
	font-size: 12px;
	font-weight: 600;
	background-color: white;
	color: var(--electricblue);
}

.Expenses{
    background-color: white;
    padding: 20px;
    .Amount{
        font-weight: 600;
        color: var(--blue);
        margin-bottom: 10px;
    }
}
.Item{
    display: flex;
    border: 1px solid var(--electricblue15);
    div{
        padding: 5px;    
        overflow: hidden;
    }
    div:not(:nth-child(4)){
        border-right: 1px solid var(--electricblue25);
    }
    div:nth-child(1){
        word-wrap: break-word;
        flex-basis: 45%;
    }
    div:nth-child(2){
        flex-basis: 22%;
    }
    div:nth-child(3){
        flex-basis: 10%;
    }
    div:nth-child(4){
        flex-basis: 23%;
    }
}
.Decline{
    background-color: var(--red10);
    border: 1px solid var(--red40);
    padding: 10px;
    margin-top: 10px;
    .Title{
        font-weight: 600;
        color: var(--red);
    }
    .Remark{
        div:nth-child(1){
            font-weight: 600;
        }
        display: flex;
        gap: 10px;
    }
}
.Approve{
    background-color: var(--electricblue10);
    border: 1px solid var(--electricblue25);
    padding: 10px;
    margin-top: 10px;
    .Title{
        font-weight: 600;
        color: var(--electricblue);
    }
    .Remark{
        div:nth-child(1){
            font-weight: 600;
        }
        display: flex;
        gap: 10px;
    }
}
.Details{
    width: 100%;
    display: flex;
}
.Left{
    width: 60%;
}
.Right{
    width: 40%;
    background-color: var(--blue);
    padding: 30px;
    > h3{
        color: var(--electricblue);
        margin-bottom: 20px;
        font-size: 24px;
    }
}
.Left{
    margin: 40px;
    .Main{
        display: flex;
        gap: 30px;
        .About{
            display: flex;
            flex-direction: column;
            gap: 10px;
            > h3{
                color: var(--electricblue);
                font-size: 24px;
                margin-bottom: 5px;
            }
            >div {
                color: var(--blue);
                display: flex;
                >div:first-child{
                    width: 150px;
                    font-weight: 600;
                }
            }
        }
    }
}
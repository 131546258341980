.Settings{
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 40px;
    gap: 40px;
    >div:not(:last-child){
        padding-bottom: 40px;
        border-bottom: 1px solid var(--blue75);
    }
    >div:not(.Head)>div{
        width: 70%;
    }
    .Head{
        display: flex;
        gap: 40px;
        >div{
            font-family: Core Sans C;
            display: flex;
            flex-direction: column;
            justify-content: center;
            max-width: 290px;
            color: var(--blue75);
            >div{
                line-height: 140%;
                font-size: 16px;
                margin-top: 8px;
            }
        }
    }
}
.Title{
   margin-bottom: 20px; 
   color: var(--blue);
}
.Btn{
    width: 150px;
}
.KBSingleItemPage {
  padding: 30px;
  color: var(--blue);
  color: var(--blue);
  width: 100%;

  .PageHead {
    display: flex;
    justify-content: space-between;
    align-items: center;

    > div {
      display: flex;
      gap: 10px;

      > p {
        font-weight: bold;
        font-size: 28px;
      }

      > svg {
        cursor: pointer;
      }
    }

    .MeatsballMenu {
      border: 1px solid var(--blue25);
      cursor: pointer;

      &:hover {
        background-color: var(--blue10);
      }
    }
  }

  .SectionTitles {
    color: var(--electricblue);
    font-weight: bold;
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 10px;
    margin-top: 25px;
  }

  .Equipment {
    > div {
      display: flex;
      flex-direction: column;
      gap: 10px;

      > p {
        display: flex;
        gap: 10px;
        align-items: center;
      }
    }
  }

  .ManifestationDescription {
    margin-bottom: 10px;
    font-size: 14px;
    font-weight: 400;
    line-height: 19.6px;
  }

  .ManifestationImage {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    align-items: center;

    > img {
      width: 229px;
      height: 229px;
    }
  }

  .AccordiansList {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  .Space{
    margin-bottom: 10px;
  }
  .Spacee{
    margin-bottom: 20px;
  }
  
}

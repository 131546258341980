.Title {
  font-size: 26px;
  font-weight: 600;
  line-height: 40px;
  color: var(--blue);
}

.FormTitle {
  font-size: 22px;
  font-weight: 600;
  line-height: 32px;
  color: var(--electricblue);
  margin-bottom: 10px;
  margin-top: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.NavMenu {
	flex-basis: 288px;
	border: 1px solid var(--blue25);
}
.icon {
	color: #0e3465;
}
.NavItem a.active .icon,
.NavItem a.active .NavPath {
	color: var(--electricblue);
	color: var(--electricblue);
}
.NavItem a:hover .icon,
.NavItem a:hover .NavPath {
	color: var(--electricblue75);
}
.logoCont {
	margin-top: 24px;
	margin-bottom: 28px;
	padding-left: 20px;
}
.NavItem {
	line-height: 24px;
	vertical-align: text-top;
}

.NavItem:hover a {
	background-color: var(--blue05);
}
.NavItem a.active {
	background-color: var(--lightblue);
}
.NavItem a::before,
.NavItem a::before {
	content: " ";
	width: 4px;
	display: block;
}

.NavItem a.active::before {
	background-color: var(--electricblue);
}
.NavItem:hover a::before {
	background-color: var(--electricblue05);
}
.LinkCont {
	padding: 18px 0px;
	line-height: 24px;
	display: flex;
	color: var(--ligthblue);
	text-decoration: none;
}
.NavItem a {
	line-height: 24px;
	display: flex;
	color: var(--ligthblue);
	text-decoration: none;
}
.NavItemWithImg .NavPath {
	margin-top: 5px;
}
.NavItem a .title {
	font-weight: 600;
}
.NavItem a .sub {
	font-style: normal;
	font-weight: 500;
	font-size: 12px;
	line-height: 14px;
}
.NavItem .svg {
	margin-left: 30px;
	margin-right: 12px;
	display: flex;
}
.NavItem svg {
	display: block;
}
.NavItem .img {
	margin-left: 30px;
	margin-right: 12px;
	display: block;
}
.img-round {
	height: 68px;
	width: 68px;
	border-radius: 50%;
	overflow: hidden;
}
.NavNotice {
	margin-left: 20px;
	font-style: normal;
	font-weight: 600;
	font-size: 12px;
	line-height: 28px;
}
.NavNotice > span {
	background-color: var(--red);
	padding: 2px 5px;
	border-radius: 20px;
	color: white;
}
.tm40 {
	margin-top: 40px;
}
.NavTitle {
	font-family: "Core Sans C";
	font-style: normal;
	font-weight: 600;
	font-size: 16px;
	line-height: 20px;
	color: var(--blue50);
	flex: none;
	order: 0;
	flex-grow: 1;
	margin-left: 20px;
	margin-bottom: 6px;
	border: none;
	outline: none;
}

.dropdown {
	width: 90%;
	background-color: transparent;
	border: none;
	font-size: 14px;
	font-weight: 600;
	color: var(--blue50);
	outline: none;
	cursor: pointer;
	display: block;
}

.dropdown option {
	font-size: 14px;
	color: var(--blue50);
	font-weight: 600;
	background-color: white;
	padding: 12px 16px;
	border-bottom: 1px solid var(--blue25);
}

.dropdown option:last-child {
	border-bottom: none;
}

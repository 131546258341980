.Default-Field{
    width: 100%;
    padding: 30px;
    display: block;
    font-family: 'Core Sans C';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    box-sizing: border-box; 
    color: var(--blue);
}
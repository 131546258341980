.Wizard{
    overflow-x: hidden;
    font-size: "Core Sans C";
    display: flex;
    flex-direction: column;
}

.Pages{
    transition: 1s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    display: flex;
    justify-content: space-between;
    flex-grow: 1;
    >div{
        display: flex;
    }
}
.PagesCont{
    flex-grow: 1;
    display: flex;
    flex-direction: column;
}
.Step{
    margin: 12px 35px;
    font-size: 14px;
    color: var(--electricblue);
    font-weight: 600;
}

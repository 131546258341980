.SignContract {
  width: 500px;
  border-top: 1px solid var(--blue25);
  color: var(--blue);

  .Details {
    padding: 30px 32px;
    line-height: 1.5;

    ol {
      margin-left: 20px;
      text-align: justify;
    }
  }

  .ButtonBox {
    padding: 20px 30px;
    display: flex;
    justify-content: space-between;
    >:nth-child(1){
      flex-basis: 75%;
    }
    >div{
      display: flex;
      gap: 10px;
    }
    > button {
      border-radius: 6px;
    }
  }
}

.Review {
  color: var(--blue);

  .Title {
    font-size: 26px;
    font-weight: 600;
    line-height: 40px;
    color: var(--blue);
  }

  .SubTitle {
    color: var(--electricblue);
    margin-bottom: 15px;
  }

  .SignatureNames {
    color: var(--blue);
  }

  .SubTitle,
  .SignatureNames {
    font-size: 22px;
    font-weight: 600;
    line-height: 32px;
  }

  .InfoRow {
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-bottom: 20px;

    .Items {
      display: grid;
      grid-template-columns: 2fr 3fr;
      font-size: 90%;

      .Key {
        font-weight: bold;
      }
    }
  }

  ol {
    padding-left: 20px;
    text-align: justify;

    li {
      margin-bottom: 15px;
    }
  }

  .ListTitles>div {
    font-weight: 600;
  }

  .Date {
    margin-top: 10px;
  }
}
.Details{
  background-color: white;
}
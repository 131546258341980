.FormsContainer {
	border: 1px #0e3465 solid;
	border-radius: 4px;

	.BoxTitle {
		color: #0e3465;
		padding: 10px;
		font-size: 20px;
		font-weight: bold;
		border-bottom: 2px lightgrey solid;
		display: flex;
		gap: 10px;
		align-items: center;
		cursor: pointer;

		.svg {
			color: var(--blue);
			width: 16px;
		}

		> *:first-child {
			flex-basis: 16px;
		}
	}
	.InputsContainer {
		padding: 20px;
	}
}
.RiskScore {
	color: var(--blue);
	font-weight: 600;
	display: flex;
	flex-flow: row-reverse;
	margin-top: 20px;
}

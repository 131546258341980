.Accordian {
  border: 2px solid #0e346540;
}
.AccordianClicked {
  border: 2px solid var(--electricblue);
}

.Accordian,
.AccordianClicked {
  border-radius: 8px;
}

.AccordianTopClicked {
  background-color: var(--electricblue10);
}

.AccordianTopClicked,
.AccordianTop {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 15px;
}

.AccordianTitleClicked {
  color: var(--electricblue);
}

.AccordianTitleClicked,
.AccordianTitle {
  font-weight: bold;
  display: flex;
  align-items: center;

  > svg {
    cursor: pointer;
  }
}

.AccordianDropDownClicked {
  background-color: var(--electricblue10);
  border-top: 1px solid var(--electricblue);
}

.AccordianDropDown {
  border-top: 1px solid #0e346540;
}

.AccordianDropDownClicked,
.AccordianDropDown {
  padding: 20px;  
}
.content{
  ul, ol{
    margin: 5px 5px 5px 20px;    
  }
}
.Modal{
    width: 600px;
    border-top: 1px solid var(--blue25);
    padding: 30px;
}
.Operations{
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin: 10px 0px;
}
.Delete{
    color: red;
}
.OpsIcon{
    color: var(--electricblue);
}
.OperationView{
    display: flex;
    justify-content: space-between;
    >.Owner{
        color: var(--electricblue);
        display: flex;
        gap: 20px;
    }
}

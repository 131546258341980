.Accordian {
  background-color: #f9f9f9;
  border: 1px solid var(--blue25);
  border-radius: 5px;

  .AccordianHeading {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 20px 10px 20px;

    .AccordianHeadingLeft {
      display: flex;
      gap: 10px;
      cursor: pointer;

      > p {
        font-size: 20px;
        font-weight: 600;
        line-height: 24px;
      }

      > svg {
        width: 20px;
        height: 20px;
      }

      &:hover {
        color: var(--blue75);
      }
    }

    > svg {
      cursor: pointer;
    }
  }

  .Content {
    padding: 20px 20px 20px 20px;
  }
}

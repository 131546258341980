.ContractRightContainer {
  border: 1px solid var(--blue50);
  border-radius: 6px;
  padding: 30px;
  overflow-y: scroll;
  max-height: calc(100vh - 80px);

  .ContractRightTop {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .Title {
      font-size: 26px;
      font-weight: 600;
      line-height: 40px;
      color: var(--blue);
    }
  }

  .SubTitle {
    color: var(--electricblue);
    margin-bottom: 15px;
    margin-top: 25px;
  }

  .SignatureNames {
    color: var(--blue);
  }

  .SubTitle,
  .SignatureNames {
    font-size: 22px;
    font-weight: 600;
    line-height: 32px;
  }

  .InfoRow {
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-bottom: 20px;

    .Items {
      display: grid;
      grid-template-columns: 2fr 3fr;
      font-size: 90%;

      .Key {
        font-weight: bold;
      }
    }
  }

  ol {
    padding-left: 20px;
    text-align: justify;

    li {
      margin-bottom: 15px;
    }
  }

  .ListTitles {
    font-weight: 600;
  }

  .Date {
    margin-top: 10px;
  }

  .ClientSignatureBox {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .SignBtn {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 5px;
      padding: 10px 20px;
      background-color: var(--electricblue);
      border: none;
      color: var(--blue);
      font-weight: 900;
      cursor: pointer;

      > svg {
        margin-bottom: -5px;
      }
    }
  }
}
.Signature{
  height: 100px;
  line-height: 100px;
  >img{
    max-height: 100px;
  }
}
.FootButtons{
  display: flex;  
  gap: 10px;
  >*{
    width: 170px;
  }
}
.Foot{
  display: flex;
  justify-content: space-between;
}
.BtnEdit{
  display: flex;
  >*:nth-child(1){
    transform: translateY(-5px);
    height: 30px;
    color: white;
  }
}

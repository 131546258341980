.EngrTasks{
    border: 1px solid var(--blue25);
    border-radius: 8px;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 16px;
    padding: 16px;    
}
.EngrTaskDetails{
    display: flex;
    justify-content: space-between;
    margin-bottom: 14px;
    line-height: var(--imgThumbSize);
}

.dot {
  display: inline-block;
  border: 1px solid red;
  width: 6.8px;
  height: 6.8px;
  line-height: 6.8px;
  text-align: center;
  color: white;
  font-weight: 600;
  font-family: "Core Sans C";
  font-size: 6px;
  position:relative;
  border-radius: 50%;
  transform: translate(-10px, 0px);
  background-color: var(--red);
}

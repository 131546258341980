.MsNotify{
    display: flex;
    justify-content: space-between;
    border: 1px solid var(--electricblue);
    line-height: 50px;
    padding: 0px 10px;
}
.Name{
}
.MsEquipments{
    display: flex;
    gap:10px;
    >div{
        transform: translateY(5px);
    }    
}

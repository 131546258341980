.TaskItem{
    margin-bottom: 24px;
    padding: 24px;
    background: #FFFFFF;
    box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.15);
    border-radius: 8px;
    }
.TaskItem>h4{
    cursor: pointer;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    color:var(--blue);    
}
.TaskItemDetails{
    line-height: 24px;
    margin: 8px 0px;
}
.TaskItem .Red{
    color: var(--red) !important;
}
.TaskItem .Red svg{
    fill: var(--red) !important;
}

.TaskEndDate{
    display: flex;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;    
    color: var(--blue50);
}
.TaskDateIcon{
    height: 20px;
    overflow: hidden;
    margin-right: 5px;
}
.TaskDateIcon svg{
    fill: var(--blue50);
}
.TaskProgress{
    margin-top: 16px;
}
.UserRoundThumb{
    border-radius: 50%;
    width: var(--imgThumbSize);
    height: var(--imgThumbSize);
    overflow: hidden;
    margin-right: 10px;
}
.UserRoundThumb img{
    width: var(--imgThumbSize);
    height: var(--imgThumbSize);

}
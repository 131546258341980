.RHead{
    color: white;
    margin-bottom: 24px;
}
.RMSList{
    display: flex;
    justify-content: space-between;
    color: var(--electricblue);
    font-weight: 600;
    margin-bottom: 16px;
}
.addMilestoneBtn{
    color: var(--electricblue);
    display: flex;
    gap: 10px;
    background-color: var(--electricblue15);
    padding: 22px;
    cursor: pointer;
}
.addMilestoneBtn:hover{
    background-color: var(--electricblue10);
}
.svg{
    color: var(--electricblue);
}   
.OpsHead{
    display: flex;
    justify-content: space-between;
}
.OpsHead h2{
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 40px;
    color: var(--blue);    
}
.Ops{
    width: 100%;
    padding: 40px;
}
.UserList div{
    line-height: 30px;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    color: var(--blue);
}
.UserList>div:first-child>div>div>div>div:first-child{
    display: flex;
}
.UserList>div:first-child>div>div>div>div:first-child>div:first-child{
    height: 30px;
    width: 30px;
    border-radius: 50%;
    overflow: hidden;
    margin-right: 20px;
}
form {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}
.FormCont {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}
.FormControl > div {
  margin-bottom: 8px;
}
.FormControl Label {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  color: var(--blue75);
}
.FormControl textarea {
  height: 150px;
}
.FormControl input,
.FormControl select,
.FormControl textarea {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: var(--blue75);
  display: block;
  padding: 5px;
  width: 100%;
  border: 1px solid var(--blue25);
  resize: none;
  background-color: white;
}
.FormControl select {
  padding: 7px 5px !important;
}
.Controls {
  display: flex;
  justify-content: right;
  margin-top: auto;
}
.Controls > div {
  min-width: 150px;
}

.SliderDetails {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: var(--blue);

  .SliderDetailsPlaceholder {
    font-size: 80%;
  }

  .SliderDetailsValue {
    font-weight: bold;
  }
}

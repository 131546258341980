.Modal {
	width: 60vw;
	padding: 40px;
	background-color: var(--blue);
	.List {
		display: flex;
		flex-direction: column;
		overflow-y: auto;
		height: 65vh;
		gap: 10px;
		> * {
			background-color: white;
		}
	}
	.FootBtn {
		margin-top: 10px;
		display: flex;
	}
}

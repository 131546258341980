.ProgressBarTrack{
    background-color: var(--blue25);
    min-height: 8px;
}
.ProgressBarBack{
 overflow: hidden;
 background-color: white;
}
.ProgressBar{
    min-height: 8px;
    width: 85%;
    color: var(--blue75);
    text-align: center;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 12px;
    padding: 6px;
    height: 8px;
}
.Details {
  width: 100%;
  display: flex;
  min-height: 100%;
  align-items: stretch;

  .DetailsBody {
    width: 65%;
    padding: 30px;
    margin-bottom: 10px;

    .DetailsBodyInfo {
      display: grid;
      grid-template-columns: 1fr 1fr;
      margin-bottom: 10px;

      > div {
        display: flex;
        margin-bottom: 15px;

        span {
          color: var(--blue);
        }

        > span:first-child {
          width: 150px;
          font-weight: bold;
          color: var(--blue);
        }
      }
    }
  }

  .DetailsMilestones {
    width: 35%;
    background-color: var(--blue);
    padding: 30px;
    height: 100vh;
    position: sticky;
    top: 0;
    right: 0;

    > h3 {
      color: var(--white);
      margin-bottom: 20px;
      font-size: 30px;
    }
  }
}
.FormsDisplay{
  display: flex;
  flex-direction: column;
  gap: 10px;
}
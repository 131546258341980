.Modal{
    width: 600px;
    border-top: 1px solid var(--blue25);
    padding: 30px;
    div{
        h4{
            color: var(--blue75);
            margin-bottom: 10px;
        }
    }
}
.Controls{
    display: flex;
    justify-content: space-between;
}
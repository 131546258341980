.Pagination {
  display: flex;
  justify-content: center;
  margin: 20px;
  margin-top: 10px;
  .Center {
    display: flex;
    gap: 10px;
    > div {
      color: var(--white);
      background-color: var(--electricblue);
      font-weight: 600;
      width: 40px;
      line-height: 30px;
      text-align: center;
    }
  }
}

.Details {
	width: 100%;
	.Left {
		padding: 50px;
		.Main {
			.Info {
				display: flex;
				flex-direction: column;
				gap: 10px;
				> div {
					background-color: var(--electricblue5);
					padding: 15px;
					justify-content: space-between;
					display: flex;
					color: var(--blue);
					> div:first-child {
						color: var(--blue75);
						font-weight: 600;
					}
				}
			}
		}
	}
	/* .Right{
        flex-basis: 40%;
        background-color: var(--blue);
        color: white;
        padding: 40px;
        .Title{
            font-size: 40px;
            margin-bottom: 20px;
        }
        .Vessels{
            display: flex;
            flex-direction: column;
            gap: 20px;            
        }
    }     */
}
.Vessel {
	background-color: var(--electricblue10);
	border: 1px solid var(--electricblue);
	color: var(--lue);
	border-radius: 8px;
	padding: 20px;
	display: flex;
	justify-content: space-between;
	.Name {
		font-size: 18px;
		font-weight: 600;
		color: var(--electricblue);
	}
	.VesselControls {
		.Delete {
			.Icon {
				color: var(--red);
			}
		}
		.Edit {
			> svg {
				stroke: var(--electricblue);
				height: 30px;
				transform: translateY(-5px);
			}
		}
	}
}
.VesselControls {
	display: flex;
}
.logo > img {
	width: 150px;
}

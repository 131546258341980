.Page{
    width: 100%;
}
.Sides{
    display: flex;
}
.Sides>div{
    height: 100vh;
    overflow-y: auto;
    padding: 40px;
}
.Left{
    flex-basis: 60%;
}
.Right{
    flex-basis: 40%;
    background-color: var(--blue);
}
.LHead{
    display: flex;
    line-height: 40px;
}
.LHead>div:first-child{
    margin-right: 20px;
}
.LTitle{
    font-family: 'Core Sans C';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    color: var(--blue);    
}

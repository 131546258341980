.Report {
	width: 100%;
	padding: 33px;
	.Range {
		background-color: var(--electricblue50);
		width: 500px;
		padding: 20px;
	}
	.Summary {
		> h3 {
			border-bottom: 2px solid var(--blue);
			margin-bottom: 20px;
			padding-bottom: 10px;
		}
		> div {
			> h4 {
				margin-bottom: 20px;
			}
		}
	}
	.Analysis {
		margin-top: 20px;
		border: 1px solid var(--electricblue);
		padding: 20px;
	}
	.TopSection {
		display: flex;
		justify-content: space-between;
		> div {
			flex-basis: 40%;
		}
	}
	.Control {
		display: flex;
		justify-content: space-between;
		> div {
			width: 150px;
			> div {
				transform: translateY(-7px);
			}
		}
	}
}

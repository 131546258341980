.Active{
    display: flex;
    width: max-content;
    line-height: 40px;
    font-style: normal;
    font-weight: 600;
    font-size: 17px;
    color: var(--blue75);
    align-items: center;
    margin-right: 20px;
    justify-content: center;
}
.Arrow{
    transition: 0.5s;
    margin-left: 10px;
}
.Filter:hover .Arrow{
    position: relative;
    top:5px;
    transform: rotateX(180deg);
}
.content{
    position: absolute;
    z-index: 1;
}
.content>ul{
    position: relative;
    background-color: white;
    box-shadow: 0px 0px 3px var(--blue75);
    display: none;
}
.Active:hover + .content>ul, .content:hover>ul{
    display: block;
}
.content>ul>li{
    list-style: none;
    min-width: 150px;
}
.content>ul>li:hover, .Selected{
    background-color: var(--lightblue);
}
.content>ul>li>span{
    color: var(--blue75);
    text-decoration: none;
    display: block;
    padding: 10px;
    cursor: pointer;
}

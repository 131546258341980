.Cont{
    width: 500px;
    padding: 24px 35px;
    display: flex;
    flex-direction: column;
}
.Head{
    color: var(--blue);
    margin-bottom: 30px;
    display: flex;
    justify-content: space-between;
}
.hgap10{
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.formLoader{
    display: flex;
    justify-content: space-between;
    font-weight: 600;
    color: var(--electricblue);
}
.Body{
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    .hgap10{
        height: 400px;
        overflow-y: auto;
    }
    .Controls{
        margin-top: auto;
        display: flex;
        justify-content: right;
        >div{
            min-width: 150px;
        }
    }
}
.EquipmentList{
    display: flex;
    flex-direction: column;
    gap: 20px;
}
.EquipmentView{
    background-color: var(--blue);
    color: white;
    border-radius: 0px 20px;
    overflow: hidden;
    >div:first-child{
        background-color: var(--electricblue);
        display: flex;
        justify-content: space-between;
        .DeleteIcon{
            cursor: pointer;
        }
    }
    >div{
        padding: 10px;
    }
}
.ExpandableCont{
    display: flex;
    flex-direction: column;
    background-color: var(--blue75);
    height: 100%;
}
.BtnOptions{
    display: flex;
    gap: 10px;
    padding: 20px;
    background-color: var(--blue75);
    height: 100%;
}
.EngineerView{
    color: var(--blue);
    border: 1px solid var(--blue25);
    padding: 20px;
    display: flex;
    justify-content: space-between;
    .Lead{
        color: var(--electricblue);
        display: flex;
        gap: 10px;
        .EngIcon{
            color: var(--electricblue);
        }
        .Delete{
            color: var(--red);
        }
        .BtnLead{
            height: 20px;
            transform: translateY(-5px);
        }
    }
}
.Engineers{
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.MilestoneDetails{
    background-color: var(--blue);
    color: white;
    height: 100%;
    width: 300px;
    display: flex;
    flex-direction: column;
    .MsHead{
        padding: 20px;

    }
}
.ListView{
    list-style: none;
    display: flex;
    flex-direction: column;
    gap: 20px;
    >li{
        display: flex;
        >div:first-child{
            width: 100px;
            font-weight: 600;
        }
    }
}
.Tab{
    .TabHead{
        display: flex;
        >div{
            border-bottom: 1px solid white;
            padding: 5px 10px;
            cursor: pointer;
        }
        .TabHeadFill{
            flex-grow: 1;
        }
        .Active{
            border: 1px solid white;
            border-radius: 5px 5px 0px 0px;
            font-weight: 600;
            border-bottom: none;
        }
    }
    .TabBody{
        padding: 20px 20px;
        border-bottom: 1px solid white;
        margin-bottom: 10px;
    }
}
.Foot{
    margin-top: auto;
}
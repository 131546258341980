.Filters {
  color: var(--blue);
  border-right: 1px solid var(--blue25);
  height: 100%;
  overflow-y: scroll;

  .FiltersContainer {
    padding: 10px 20px 0 20px;

    .FilterBoxes {
      border-bottom: 1px solid var(--blue25);
      padding-bottom: 20px;
      margin-top: 20px;

      .FilterTitle {
        font-weight: bold;
        font-size: 20px;
        line-height: 24px;
        margin-bottom: 15px;
      }
    }
  }

  .FilterButtonsBox {
    border-top: 1px solid var(--blue25);
    padding: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 15px;
  }
}

.CheckBox {
  display: flex;
  align-items: center;
  gap: 5px;

  > input[type="checkbox"] {
    width: 20px;
    height: 20px;
  }
}

.SearchBox {
  display: flex;
  border: 1px solid var(--blue75);
  border-radius: 10px;
  overflow: hidden;
  height: 40px;
}
.SearchBox .SearchIcon {
  margin: 12.2px;
  font-size: 12px;
}
.SearchBox input {
  border: none;
  font-family: "Core Sans C";
}
.SearchBox input:focus {
  outline: none;
}

.LoginPage{
    display: flex;
    min-height: 100vh;
    background-color: var(--blue);
}
.LoginPage>.LoginLeft{
    flex-basis: 55%;
    background-color: white;
}
.LoginPage>.LoginRight{
    flex-basis: 45%;
    background:url(../../Assets/Images/authbg.png);
    mix-blend-mode: overlay;
}
.LoginForm{
    margin: auto;
    width: 380px;
    transform: translateY(50vh);
    margin-top: -180px;
}
.LoginForm>div{
    margin-bottom: 14px;
}
.LoginForm h1{
    font-style: normal;
    font-weight: 600;
    font-size: 36px;
    line-height: 45px;
    color: var(--blue);    
    margin-bottom: 32px;
}
.ForgetPWLink{
    color: var(--blue75);
    display: flex;
    justify-content: right;
}
.ForgetPWLink a{
    color: var(--electricblue);
    text-decoration: none;
}
.LoginTitle{
    font-size: 24px;
    font-weight: 600;
    color: white;
    position: absolute;
    left: 60%;
    margin-top: 40px;
    z-index: 100;
}
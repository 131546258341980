.AddJob {
	width: 100%;

	.Top {
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 30px;

		.BackBox {
			display: flex;
			align-items: center;
			gap: 10px;
			cursor: pointer;
			border: 1px solid #0e346540;
			padding: 15px;

			span {
				color: var(--blue);
				font-weight: bold;
			}

			&:hover {
				background-color: whitesmoke;
			}
		}
	}

	.Body {
		margin-top: 50px;
		padding: 20px;
		margin: 50px auto 0 auto;
		width: 60%;

		.Title {
			color: var(--blue);
			margin-bottom: 20px;
		}
	}
}
.row{
	display: flex;
	justify-content: space-between;
}
.modalCont{
	height: 0px;
}
.modal{
	position:relative;
	width:inherit;
}
.EquipmentView{
    background-color: var(--blue);
    color: white;
    border-radius: 0px 20px;
    overflow: hidden;
    >div:first-child{
        background-color: var(--electricblue);
        display: flex;
        justify-content: space-between;
        .DeleteIcon{
            cursor: pointer;
        }
    }
    >div{
        padding: 10px;
    }
}
.EquipmentList{
	display: flex;
	flex-direction: column;
	gap: 10px;
}
.Notifications {
	display: flex;
	flex-direction: column;
	padding: 40px;
	width: 100%;
}
.Head > h2 {
	font-family: "Core Sans C";
	font-style: normal;
	font-weight: 600;
	font-size: 32px;
	line-height: 40px;
	text-transform: capitalize;
	color: var(--neutrallight);
}
.Cont {
	flex-grow: 1;
}
.Cont > div {
	margin-top: 24px;
}

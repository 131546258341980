.Terms {
  color: var(--blue);

  .Title {
    font-size: 26px;
    font-weight: 600;
    line-height: 40px;
    color: var(--blue);
    margin-bottom: 20px;
  }

  ol {
    padding-left: 20px;

    li {
      margin-bottom: 15px;
    }
  }

  .ListTitles {
    font-weight: 600;
    font-size: 110%;
  }

  .AccordianLists {
    display: flex;
    flex-direction: column;
    gap: 15px;
    margin: 20px 0;
  }
}

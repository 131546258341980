.Confirm {
  width: 500px;
  display: flex;
  flex-direction: column;
}
.Confirm > div {
  padding: 20px;
}
.ConfirmContent {
  display: flex;
  border-top: 5px solid var(--red);
}
.ConfirmOptions {
  display: flex;
  gap: 10px;
}
.ConfirmIcon {
  color: var(--red);
}
.ConfirmIconCont {
  margin-right: 20px;
}
.ConfirmText {
  font-family: "Core Sans C";
}
.ConfirmTitle {
  font-size: 16px;
  color: var(--blue75);
  margin-bottom: 10px;
}
.ConfirmText {
  font-size: 14px;
  color: var(--blue);
}
.ConfirmControl {
  display: flex;
  justify-content: flex-end;
}
.ConfirmControl > div:nth-child(1) {
  margin-right: 10px;
}

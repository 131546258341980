.Templates {
	width: 100%;
	padding: 40px;

	.TemplateList {
		margin-top: 30px;
		display: grid;
		grid-template-columns: 1fr 1fr 1fr;
		gap: 25px;
	}
}

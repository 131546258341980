.ContractSigned {
  padding: 0 200px;
  color: var(--blue);

  .Top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 30px;
  }

  .Details {
    margin-top: 30px;
  }

  .Title {
    font-size: 24px;
    font-weight: 600;
    line-height: 32px;
  }

  .Alert {
    background: var(--backgrounds-green-subtler);
    width: Fixed (480px) px;
    padding: 20px;
    border: 1px var(--blue25) solid;
    border-radius: 4px;
    margin-bottom: 20px;

    .AlertTitle {
      font-size: 20px;
      font-weight: 600;
      color: var(--green-dark);
    }

    .AlertMessage {
      font-size: 16px;
      color: var(--blue);
      margin-top: 10px;
      line-height: 1.5;
    }
  }
  .Important {
    font-size: 24px;
    font-weight: 600;
    line-height: 40px;
    color: var(--electricblue);
  }
}

@font-face {
  font-family: "Core Sans C";
  src: url("./Assets/fonts/CoreSansC/CoreSansC-45Regular.woff");
}
* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  font-family: "Core Sans C";
}
:root {
  --white: #ffffff;
  --electricblue: #14b8ff;
  --electricblue75: rgba(20, 184, 255, 0.75);
  --electricblue50: rgba(20, 184, 255, 0.5);
  --electricblue25: rgba(20, 184, 255, 0.25);
  --electricblue15: rgba(20, 184, 255, 0.15);
  --electricblue10: rgba(20, 184, 255, 0.1);
  --electricblue5: rgba(20, 184, 255, 0.05);
  --blue: #0e3465;
  --blue75: rgba(14, 52, 101, 0.75);
  --blue50: rgba(14, 52, 101, 0.5);
  --blue25: rgba(14, 52, 101, 0.25);
  --blue10: rgba(14, 52, 101, 0.1);
  --blue05: rgba(14, 52, 101, 0.05);
  --border-color-default: #0e346540;
  --green: #4ecb71;
  --backgrounds-green-subtler: #4ecb711a;
  --green10: rgba(78, 203, 113, 0.1);
  --green-dark: #317c46;
  --lightblue: #e1f6ff;
  --red40: rgba(246, 71, 64, 0.4);
  --red05: rgba(246, 71, 64, 0.05);
  --red10: rgba(246, 71, 64, 0.1);
  --red: #f64740;
  --yellow: #fac815;
  --yellow10: rgba(250, 200, 21, 0.1);
  --yellow40: rgba(250, 200, 21, 0.4);
  --neutrallight: #172b4d;

  --imgThumbSize: 32px;
  --imgThumbSize48: 48px;
  --imgThumbSixe28: 28px;
}
.electricblue {
  color: var(--electricblue);
}
.bgRed {
  background-color: var(--red);
}
.bgBlue {
  background-color: var(--blue);
}
.bgElectricBlue {
  background-color: var(--electricblue);
}
.bgGreen {
  background-color: var(--green);
}
/* width */
::-webkit-scrollbar {
  width: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  background: var(--lightblue);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--electricblue);
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: var(--blue);
}
.ModalHead {
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 24px 32px;
}
.ModalHead h3 {
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  color: var(--blue);
}
.slide-pane__content {
  padding: 0px !important;
}
.justify-between-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: var(--blue);
}
.mt-10 {
  margin-top: 10px;
}
.mb-10 {
  margin-bottom: 10px;
}
.fw-bold {
  font-weight: bold;
}

.ReportModal{
    width: 600px;
    overflow-x: hidden;
    font-size: "Core Sans C";
    height: 620px;
}
.Report>div{
    padding-left: 35px;
    padding-right: 35px;
}
.ReportBody{
    height: 500px;
    overflow: auto;
    .Scrollable{
        height: 400px;
        overflow: auto;
    }
}
.ReportBody>div{
    width: 530px;
    overflow: hidden;
}
.Foot{
    margin-top: auto;
}
.ReportsCont{
    margin: 0;
    height:600px;
}
.Step{
    margin: 12px 35px;
    font-size: 14px;
    color: var(--electricblue);
    font-weight: 600;
}
.Reports{
    transition: 1s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    display: flex;
    justify-content: space-between;
    width: 1800px;
}
.Reports>div{
    width: 100%
}
.Report{
    display: flex;
    flex-direction: column;
    height: 530px;
}
.Head{
    color: var(--blue);
    margin-bottom: 30px;
}
.FormControl>textarea:focus{
    outline: 0;
}
.FormControl>textarea{
    padding: 10px;
    border: 1px solid var(--blue25);
    width: 100%;
    resize: none;
    height: 150px;
}
.Pictures{
    margin:16px 0px;
}
.Buttons{
    justify-content: right;
    display: flex;
}
.Buttons>button{  
    width: 150px;
}
.Logs{
    display: flex;
    flex-direction: column;
    gap: 5px;
    margin-bottom: 10px;
}
.LogsCont{
    height: auto;
}
/* Report log style Begins*/
.Log{
    background-color: var(--blue);
    color: white;
}
.LogHeadCont{
    justify-content: space-between;
    display: flex;
}
.LogHead{
    flex-grow: 1;
    display: flex;
    gap: 10px;
    padding: 10px;
    cursor: pointer;
}
.LogHeadIcon{
    color: white;
}
.LogCont{
    border-top: 1px solid white;
    padding: 10px;
}
.Form label{
    color:white;
}
.Form input, .Form textarea{
    background-color: var(--blue);
    border: 1px solid white;
    color:white;
}
.FormRow{
    display: flex;
    justify-content: space-between;
}
.FormRow>div{
    flex-basis: 48%;
}
.Wrapper{
    transition: 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    margin: 0px;
    overflow: auto;
}
.LogHeadIcons{
    padding: 10px;
    gap: 10px;
    display: flex;
}
.hgap10{
    display: flex;
    flex-direction: column;
    gap: 10px;
}
/* Report log style End*/
.ServiceReport{
    border: 1px solid var(--blue25);
    margin: 40px;
    border-radius: 8px;
    color: var(--blue);
}
.Row{
    display: flex;
    justify-content: space-between;
    padding: 24px 32px;
}
.ReportCat{
    margin: 32px;

}
.ReportCat>h4{
    color: var(--electricblue);
    margin-bottom: 16px;
}
.ReportCat>div{
    display: flex;
    margin-bottom: 24px;
}
.ReportCat>div>h5{
    width: 152px;
}
hr{
    color: var(--blue25);
}
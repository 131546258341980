.Head{
    display: flex;
    justify-content: space-between;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 120%;
    color: var(--blue);    
}
.Title{
    display: flex;
    padding-bottom: 40px;
}
.Title>div:first-child{
    padding-right: 12px;
}

.TaskHead {
	display: flex;
	justify-content: space-between;
}
.TaskHead h2 {
	font-style: normal;
	font-weight: 600;
	font-size: 32px;
	line-height: 40px;
	color: var(--blue);
}
.Tasks {
	width: 100%;
	padding: 40px;
}
.CurrentTasks {
	margin-top: 24px;
	margin-bottom: 100px;
}
.CurrentTasks .TableHead > div:first-child {
	font-style: normal;
	font-weight: 600;
	font-size: 20px;
	line-height: 24px;
	color: var(--electricblue);
}

.Cont{
    box-sizing: border-box;

    /* Auto layout */
    
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 16px;
    height: 64px;
    justify-content: space-between;
    
    background: #FFFFFF;
    border: 1px solid #4A678B;
    border-radius: 4px;    
    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 0;
    overflow: hidden;
}
.content{
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 16px;
    gap: 24px;
    height: 64px;
    overflow:visible;
}
.name{
    font-family: 'Core Sans C';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: var(--blue); 
    cursor: pointer;   
}
.count{
    background-color: var(--electricblue5);
    color: var(--electricblue);
    padding: 0px 3px;
    margin-left: 3px;
    font-size: 12px;
    display: inline-block;
    border: 1px solid var(--electricblue);
}
.buttons{
    display: flex;
}
.Cont:hover .controls{
    transform: translate(-40px, 2px);

}
.controls{
    transition: 0.5s cubic-bezier(0.19, 1, 0.22, 1);
    display: flex;
    transform: translate(0px, 2px);
    width: 160px;
}
.Visible{
    margin-left: 10px;
    transform: translateY(5px);
}
.Visible .svg{
    color: var(--electricblue);
}
.Visible svg{
    width: 24px;
}
.Close{
    transform: translateY(5px);
    margin-left: 20px;
}
.Close:hover .svgclose{
    color: var(--electricblue);
}
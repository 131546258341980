.AddLogIcon{
    color: var(--electricblue);
}
.AddLog{
    color: var(--electricblue);
    padding: 10px;
    gap: 10px;
    display: flex;
    background-color: var(--electricblue5);
    line-height: 100%;
    cursor: pointer;
}

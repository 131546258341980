.RoundedStatus, .RoundedClose{
    background-color:rgba(14, 52, 101, 0.1);
    height: 24px;
    width: 24px;
    border-radius: 50%;
    margin-right: 10px; 
}
.RoundedStatus>div{
    margin: auto;
    width: 14px;
    line-height: 24px;
}
.RoundedClose>div{
    margin: auto;
    width: 12px;
    line-height: 24px;

}
.RoundedStatusCont{
    display: flex;
    cursor: pointer;
}
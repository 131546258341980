.KBSingleList {
	color: var(--blue);
	border: 1px solid var(--blue25);
	border-radius: 4px;
	padding: 15px;
	cursor: pointer;
	max-width: 100%;
	width: 100%;
	overflow: hidden;
	box-sizing: border-box;

	&:hover {
		background-color: var(--blue05);
	}

	.Top {
		display: flex;
		align-items: flex-end;
		gap: 5px;

		.Name {
			font-weight: bold;
			font-size: 16px;
			line-height: 16px;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			max-width: 90%;
		}
	}

	.Description {
		font-size: 14px;
		line-height: 18px;
		margin-top: 10px;
		display: -webkit-box;
		-webkit-line-clamp: 4; /* Limits the text to 3 lines */
		-webkit-box-orient: vertical;
		overflow: hidden;
		text-overflow: ellipsis;
		max-width: 100%;
		white-space: normal;
	}
}

.FormRow{
    display: flex;
    justify-content: space-between;
}
.FormRow>div{
    flex-basis: 45%;
}
.LCont{
    margin-top: 30px;
    flex-direction: column;
    flex: 1;
}
.Bottom>div>div{
    width: 160px;
}
.Bottom>div{
    display: flex;
    position: absolute;
    bottom: 40px;
}
.Bottom{
    display: flex;
    justify-content: right;
}
.RHead{
    color: white;
    margin-bottom: 24px;
}
.RMSList{
    display: flex;
    justify-content: space-between;
    color: var(--electricblue);
    font-weight: 600;
    margin-bottom: 16px;
}
.addMilestoneBtn{
    color: var(--electricblue);
    display: flex;
    gap: 10px;
    background-color: var(--electricblue15);
    padding: 22px;
    cursor: pointer;
}
.addMilestoneBtn:hover{
    background-color: var(--electricblue10);
}
.svg{
    color: var(--electricblue);
}   
.Modal{
    width: 500px;
    height: 500px;
    padding: 30px 32px;
    border-top: 1px solid var(--blue25);
    display: flex;
    flex-direction: column;
    >.Foot{
        margin-top: auto;
        display: flex;
        justify-content: flex-end;
        >div{
            width: 150px;
        }
    }
}
.mb{
    margin-bottom: 30px;
}
.Items{
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-bottom: 16px;
    >:hover{
        background-color: var(--blue10);
    }

}
.FileInput{
    display: none;
}
.Delete{
    width: 0px;
    transform: translateX(-30px);
}
.Item{
    display: flex;
    gap: 16px;
    /* border: 2px solid var(--blue25); */
    padding: 10px 0px 10px 10px;
    .Img{
        border-radius: 8px;
        width: 120px;
        height: 120px;
        background-color: var(--blue25);
        overflow: hidden;
        >label{
            display: block;
            height: 120px;
            width: 120px;
            >img{
                height: 120px;
            }
        }
    }
    .FormCont{
        flex-grow: 1;
        .Details{
            display: flex;
            justify-content: space-between;
        }
        
    }
}
.Milestone{
    margin-bottom: 16px;
    display: flex;
    padding: 16px;
    /* background: linear-gradient(0deg, rgba(78, 203, 113, 0.1), rgba(78, 203, 113, 0.1)), #FFFFFF; */
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    border-radius: 4px;}
.Title{
    font-family: 'Core Sans C';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: var(--blue50);    
}
.count{
    background-color: var(--blue50);
    display: inline-block;
    font-size: 12px;
    padding: 0px 5px;
    margin-left: 10px;
    color: var(--white);
}
.Value{
    display: flex;
    >div{
        width: 80px;
    }
    .Currency{
        background-color: var(--blue25);
        color: var(--blue);
        margin: 8px 0px;
        line-height: 200%;
        padding-left: 10px;
        width: 50px;
    }
}
.Submit{
    /* width: 50%; */
    margin: 5px;
    margin-bottom: 10px;
}
.pdf{
    object{
        pointer-events: none;
    }
}
.UploadTypeName{
    font-size: 12px;
    font-weight: 600;
    background-color: white;
    color: var(--electricblue);
}
.FormsContainer {
    border: 1px #0e3465 solid;
    border-radius: 4px;
    margin-bottom: 10px;
    padding: 0 5px;
  
    .BoxTitle {
      color: #0e3465;
      padding: 10px;
      font-size: 18px;
      font-weight: bold;
      border-bottom: 2px lightgrey solid;
      display: flex;
      gap: 10px;
      align-items: center;
      cursor: pointer;
  
      .svg {
        color: var(--blue);
        width: 16px;
      }
  
      
    }
    
    
  }
  .TableRecords{
    margin-bottom: 10px;
  }
  .TableRecords > div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    border-bottom: 1px solid var(--blue75);
    /* border-right: 1px solid var(--blue75); */
    padding: 0px 10px;
  }
  .TableRecords > div:last-child {
    border-bottom: none; /* Remove border-bottom from the last child */
  }
  .TableRecords > div > div:nth-child(1) {
    text-align: left; /* For the name */
  }
  .TableRecords > div > div:nth-child(2) {
    text-align: center; /* For the amount */
  }
  .TableRecords > div > div:nth-child(3) {
    text-align: right; /* For the receipt */
  }
  /* Wrapper for the progress bar */
.ProgressWrapper {
    margin-top: 20px;
    width: 100%;
    padding: 5px 0;
    margin-bottom: 20px;
  }
  
  /* Container for the progress bar */
  .ProgressBar {
    width: 100%;
    background-color: #e0e0e0; /* Light grey background */
    height: 8px;
    border-radius: 4px;
    position: relative;
  }
  
  /* Progress fill (dynamically set based on the completion) */
  .ProgressFill {
    height: 100%;
    background-color: var(--primaryColor); /* Use your theme's primary color */
    border-radius: 4px;
    transition: width 0.3s ease-in-out;
  }
  
  /* Labels for each progress stage */
  .ProgressLabels {
    display: flex;
    justify-content: space-between;
    margin-top: 5px;
    font-size: 12px;
    color: var(--textColor); /* Use a suitable text color */
  }
  
  /* You can adjust colors based on stage completion if needed */
  .ProgressFill {
    background-color: var(--electricblue); /* Default green for filled stages */
  }
  
  .ProgressFill[data-stage="submitted"] {
    background-color: #ff9800; /* Orange for submitted stage */
  }
  
  .ProgressFill[data-stage="approved"] {
    background-color: #2196f3; /* Blue for approved stage */
  }
  
  .ProgressFill[data-stage="paymentApproved"] {
    background-color: #9c27b0; /* Purple for payment approved */
  }
  
  .ProgressFill[data-stage="scheduled"] {
    background-color: #f44336; /* Red for scheduled stage */
  }
  .StatusText{
color: var(--blue) ;
font-size: 15px;
font-weight: bold;
  }
  .ProgressInfo{
    display: flex;
    margin-bottom: 6px;
    justify-content: space-between;
  }
  .Title{
    font-size: 15px;
    font-weight: 600;
    color: var(--red);
    margin-bottom: 10px;
}
.Details{
    display: flex;
    justify-content: space-between;
}
.Approve{
    margin-top: 20px;

    margin-bottom: 20px;
}
.Remark{
    font-weight: 600;
    font-size: 16px;
    color: var(--blue75);
}
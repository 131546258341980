.PageHead{
    display: flex;
    justify-content: space-between;
}
.PageHead h2{
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 40px;
    color: var(--blue);    
}
.PHTitle{
    display: flex;
}
.PHIcon{
    padding-right: 14px;
}
.PHIcon svg{
    height: 38px;
}
.btnAdd{
    margin-left: 20px;
}
.TopSearch{
    display: flex;
}

.Page {
	display: flex;
	width: 100%;
}
.TDLeft {
	flex-basis: 60%;
	border-right: 1px solid var(--blue75);
}
.TDRight {
	flex-basis: 40%;
	padding: 0px 0px 0px 0px;
}
.Readonly {
	position: fixed;
	z-index: 0;
	opacity: 0.4;
	width: 100%;
	height: 100vh;
}
.TDRight .Title {
	padding-bottom: 24px;
	font-style: normal;
	font-weight: 600;
	font-size: 24px;
	line-height: 32px;
	color: var(--blue);
}
.TDLeft {
	padding: 50px;
}
.GeneralTaskInfo > div {
	color: var(--blue);
	display: flex;
}
.GeneralTaskInfo > div > div {
	line-height: 150%;
	margin-bottom: 20px;
}
.GeneralTaskInfo > div > div:nth-child(1) {
	font-weight: 600;
	font-size: 16px;
	flex-basis: 200px;
}
.TaskDetailsBtns {
	display: flex;
}
.TaskDetailsBtns > div {
	margin-right: 20px;
}

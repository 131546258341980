.Login-Page{
    background-image: url(../../Assets/Images/image10.png);
    background-repeat: no-repeat;
    background-position: right 0px;
    min-height: 100vh;
    display: flex;
}
.Login-Left{
    background-color: var(--blue);
    flex-basis: 60vw;
    padding-left: 80px;
    padding-top: 55px;
}
.Login-Title{
    color: var(--white);
    font-family: 'Roboto';
    width: 650px;
    margin-top: 150px;
    font-size: 64px;
    line-height: 120%;
}
.Login-Text{
    width: 490px;
    font-family: 'Core Sans C';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 150%;
    color: var(--white);
    margin-top: 16px;
}
.Login-Form{
    margin-top: 48px;
    margin-right: 122px;
}
.Login-Form input{
    margin-bottom: 20px;
}
.Login-Form button{
    margin-bottom: 150px;
}
.Tracking{
    height: 100vh;
    color: var(--electricblue);
    display: flex;
    justify-content: center;
    align-items: center;
}
@keyframes blink {
    0% {background-color: var(--electricblue5);}
    25% {background-color: white;}
    50% {background-color: var(--electricblue5);}
    75% {background-color: white;}
    100% {background-color: var(--electricblue5);}
  }

.Tracking h3{
    background-color: var(--electricblue5);
    border: 1px solid var(--blue25);
    margin: 40px;
    padding: 20px;
    animation-name: blink;
    animation-duration: 5s;
    animation-iteration-count: infinite;
    animation-fill-mode: both;
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

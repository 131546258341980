.AITroubleshooting {
  border-left: 1px solid var(--blue25);
  height: 100vh;
  display: grid;
  grid-template-rows: 90% 10%;

  .Top {
    .Title {
      padding: 15px 15px;
      color: var(--blue);
      font-weight: bold;
      font-size: 24px;
      line-height: 32px;
    }
    .ChatArea {
      padding: 15px 15px 0px 15px;
      border-top: 1px solid var(--blue25);
      height: 90%;
      overflow-y: scroll;

      .UserMessages,
      .AIMessages {
        display: flex;

        > div {
          width: 85%;
          border: 1px solid var(--blue25);
          border-radius: 4px;
          padding: 15px;
          font-size: 14px;
          line-height: 16.8px;
          margin-bottom: 15px;

          /* .ChatTime{
            font-size: 10px;
            color: var(--blue25);
            margin-top: 5px;
          text-align: end;
          } */
        }
      }

      .UserMessages {
        justify-content: flex-end;

        > div {
          background-color: var(--blue);
          color: white;
        }
      }
      .AIMessages {
        align-items: flex-start;

        > div {
          color: var(--blue);
          align-items: flex-end;
        }
      }
    }
  }

  .UserInput {
    padding: 20px 15px;
    border-top: 1px solid var(--blue25);

    > input {
      width: 100%;
      padding: 10px;
      border: 1px solid var(--blue25);
      border-radius: 4px;
    }
  }
}

.AddContract {
	width: 100%;

	.Top {
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 15px 30px;

		.BackBox {
			display: flex;
			align-items: center;
			gap: 10px;
			cursor: pointer;
			border: 1px solid #0e346540;
			padding: 10px;

			span {
				color: var(--blue);
				font-weight: bold;
			}

			&:hover {
				background-color: whitesmoke;
			}
		}

		.TopProgress {
			display: flex;
			gap: 30px;

			> div {
				display: flex;
				align-items: center;
				cursor: pointer;

				> span {
					margin-left: -5px;
				}
			}
		}

		.TopButtons {
			display: flex;
			gap: 10px;
		}
	}

	.Body {
		padding: 20px 200px;
	}
}

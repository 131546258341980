.MilestoneTemplates{
    padding: 40px;
    width: 100%;
}
.Templates{
    --gap:20px;
    --width:100%;
    display: flex;
    flex-wrap: wrap;
    gap: var(--gap);
}
.Templates > div{
    background: #FFFFFF;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    border-radius: 8px;    
    width: calc(calc(var(--width) / 3) - 14px);
    min-width: 300px;
    height: 160px;
}
.Templates > div{
    margin-top: 20px;
}
.NoTaskTemplate{
    display: block;
    width: 100%;
    color: var(--electricblue);
    padding: 20px;
    margin-top: 10px;
    background-color: var(--electricblue5);
}
.FeedbackModel{
    margin: 30px;
    width: 500px;
}
.Page>div{
    height: 500px;
    display: flex;
    flex-direction: column;
}
.Step{
    color: var(--electricblue);
    font-size: 14px;
    font-weight:600;
    margin: 16px 24px;
}
.BtnOptions{
    display: flex;
    flex-direction: column;
    gap: 5px;
}
.BtnOptions>button{
    width: 100px;
}
.FeedbackModel textarea{
    height: 200px;
    border: 1px solid var(--blue50);
    margin: 0px;
    padding: 10px;
    width: 100%;
}
.FormControl{
    margin: 20px 0px;
}
.FeedbackModel h3{
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 40px;
    margin-bottom: 24px;
    color: var(--blue);    
}
.ButtonControls{
    display: flex;
    justify-content: right;
    margin-top: auto;
}
.ButtonControls>div{
    width: 150px;
}
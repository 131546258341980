.MilestoneSelect{
    height: 100vh;
    width: 40vw;
    padding: 40px;
    background-color: var(--blue);
}
.RHead{
    color: white;
    margin-bottom: 24px;
}
.RMSList{
    display: flex;
    justify-content: space-between;
    color: var(--electricblue);
    font-weight: 600;
    margin-bottom: 16px;
}
.Milestones{
    height: calc(100vh - 200px);
    overflow-y: auto;
}
.Milestones>div{
    margin-right: 10px;
    margin-bottom: 30px;
}
.Head{
    color: white;
    margin-bottom: 30px;
}
.Controls{
    margin-top: 10px;
    display: flex;
    justify-content: right;
    gap: 10px;
}
.Controls>div{
    width: 150px;
}
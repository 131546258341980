.Default-Button {
  display: block;
  width: 100%;
  padding: 20px;
  background-color: var(--electricblue);
  font-family: "Core Sans C";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  color: var(--white);
  cursor: pointer;
}
.Btn-Small {
  width: 100%;
  padding: 8px;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  border: none;
  cursor: pointer;
}
.Btn-Mid {
  width: 100%;
  height: 40px;
  padding: 10px 16px;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  border: none;
  cursor: pointer;
}
.Btn-Download {
  width: 100%;
  height: 40px;
  padding: 10px 16px;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  border: none;
  cursor: pointer;
  background: none;
  border:var(--blue25);
  border: 1px solid; 
  border-radius: 2px;
  display: flex;
  align-items: center;
  color:rgba(14, 52, 101, 0.8);
}
.Btn-Tny {
  width: 100%;
  height: 30px;
  padding: 5px 10px;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  border: none;
  line-height: 22px;
  cursor: pointer;
}
.Active {
  cursor: pointer;
  background-color: var(--electricblue);
  color: var(--white);
}
.Inverse {
  cursor: pointer;
  background-color: var(--lightblue);
  color: var(--electricblue);
}
.btnIcon {
  margin-right: 6px;
}
.iconbtn .btnIcon {
  margin-right: 0px;
}
.iconbtn {
  width: 40px;
  height: 40px;
  padding: 0px;
}
.btnIcon svg {
  margin: 0px;
  /* background-color: red; */
}
.btnContent {
  display: flex;
  justify-content: center;
  align-items: center;
}
.Btn-Tny svg {
  width: 20px;
}

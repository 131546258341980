.Milestone{
    font-style: normal;
    font-size: 20px;
    line-height: 24px;
    color: var(--blue);
}
.MSRow{
    display: flex;
    justify-content: space-between;
}
.MSRowG{
    display: flex;
    justify-content: space-between;
    gap: 20px;
    >div{
        border: 1px solid var(--green); 
        padding: 5px;
    }
}
.Pointer{
    background-color:  var(--electricblue) !important;
}
.Circle{
    background-color:rgba(14, 52, 101, 0.1);
    height: 24px;
    width: 24px;
    border-radius: 50%;
    margin-right: 24px;
}
.VerticalSpace{
    width: 4px;
    height: 30px;
    margin: 12px 10px;
}
.VerticalSpace::before{
    content: " ";
    display: block;
    transform: translateY(-20px);
    background-color: var(--green10); 
    min-height: 60px;
    
}
.Approved{
    background-color:rgba(14, 52, 101, 0.1);
    height: 24px;
    width: 24px;
    border-radius: 50%;
    margin-right: 24px;

    background-color: var(--green);
    background-image: url(../../Assets/Images/Mark.png);
    background-repeat: no-repeat;
    background-position: center;
}
.PartApproved{
    background-color:rgba(14, 52, 101, 0.1);
    height: 24px;
    width: 24px;
    border-radius: 50%;
    margin-right: 24px;

    background-color: var(--electricblue);
    background-image: url(../../Assets/Images/Mark.png);
    background-repeat: no-repeat;
    background-position: center;
}
.NotApproved{
    background-color:rgba(14, 52, 101, 0.1);
    height: 24px;
    width: 24px;
    border-radius: 50%;
    margin-right: 24px;
}

.MS-Actions{
    width: 100%;
    display: flex;
    justify-content: space-around;
    padding-top: 20px;
}
.MS-Actions>div{
    flex-basis: 45%;
}
.Completed{
    background-color: var(--green);
    color: white;
}
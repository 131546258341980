.Modal{
    width: 600px;
    border-top: 1px solid var(--blue25);
    padding: 30px;
}
.body{
    display: flex;
    flex-direction: column;
    gap: 10px;
    color: var(--blue75);
    >div{
        display: flex;
        line-height: 25px;
        gap: 20px;
    }
}
.foot{
    margin-top: 20px;
    display: flex;
    gap: 10px;
    >div{
        width: 150px;
    }
}
.CheckBox{
    display: inline-block;
    border: 2px solid var(--electricblue25);
    cursor: pointer;
    >:hover{
        background-color: var(--electricblue10);
        }
    >div{
        display: flex;
        justify-content: center;
        width: 25px;
        height: 25px;
        color: var(--electricblue);    
        > svg{
            margin: auto;
        }
    }
}
.ClientContract {
  display: flex;

  .ContractLeft {
    background-color: var(--blue);
    flex-basis: 30%;
    background-repeat: no-repeat;
    background-position: right 0px;
    padding: 50px 50px 50px 50px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: sticky;
    height: 100vh;
    top: 0;
    left: 0;

    .ContractLeftContent {
      .title {
        color: var(--white);
        font-size: 32px;
        font-weight: bold;
        margin-bottom: 15px;
      }
      .text {
        color: var(--white);
        margin-bottom: 15px;
        line-height: 20px;
      }
      .key {
        color: var(--electricblue);
        font-weight: bold;
      }
    }
  }

  .ContractRight {
    flex-basis: 70%;
    background-color: white;
    padding: 40px 30px;
    max-height: 100%;
    position: sticky;
  }
}

.TableHead {
  display: flex;
  justify-content: space-between;
  width: 100%;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  color: var(--blue);
  margin-top: 20px;
  padding: 10px 33px 5px 33px;
  border-bottom: 2.5px solid var(--blue75);
}
.TableHead > div:first-child {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  color: var(--electricblue);
  transform: translateX(-33px);
}
.TableNoRecord {
  background-color: var(--electricblue5);
  font-size: 16px;
  font-weight: bold;
  padding: 20px;
  color: var(--electricblue);
}
.TableRecords {
  width: 100%;
}
.TableRecord:hover {
  background-color: var(--blue05);
  cursor: pointer;
}
.TableRecord > div {
  padding: 10px 0px;
  color: var(--blue);
  font-size: 14px;
}
.TableRecords > div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  border-bottom: 1px solid var(--blue75);
  border-right: 1px solid var(--blue75);
  padding: 0px 10px;
}
.DataTable {
  width: 100%;
}

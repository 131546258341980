.CM-Page{
    display: flex;
    min-height: 100vh;
}
.CM-Left{
    flex-basis: 30vw;
    padding-left: 80px;
    background-color: var(--blue);
}
.CM-Right{
    flex-basis: 70vw;
    height: 100vh;
    overflow: auto;
}
.CMRight{
    padding-top: 44px;;
    padding-left: 110px;
    padding-right: 110px;    
}
.CM-Logo{
    margin-top: 80px;
}
.CM-Contact{
    color: var(--white);
    margin-top: 55vh;
}
.CM-Contact>h1{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 600;
    font-size: 36px;
    line-height: 45px;   
    margin-bottom: 8px;
}
.CM-Contact p{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;   
    margin-bottom: 8px; 
}
.CM-Contact>p>span{
    color: var(--electricblue);
}
.CM-Back-Arrow{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    color:var(--blue75);    
    text-decoration: none;
}
.CM-Back-Arrow img{
    height: 16px;
    margin-right: 20px;
}
.Cont-Space{
    margin-top: 44px;
    color: var(--blue);
    display: flex;
    justify-content: space-between;
    margin-bottom: 40px;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
}
.Cont-Space h5{
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 16px;    
}
.CM-Right h6{
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;    
}
.CM-Buttons{
    display: flex;
    justify-content: space-between;
    gap:20px;
    width: 100%;
    margin-top: 8px;
}
.CM-Buttons>div{
    background-color: aqua;
    flex-basis: 48%;
}
.CM-Milestones{
    margin-top: 24px;
    width: 100%;
}
.info{
    font-size: 16px;
    font-weight: 00;
    color: var(--electricblue);
    background-color: var(--electricblue5);
    padding: 20px;
}
.msNotifyCont{
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 20px;
}
.FileInput{
    display: none;
}
.NoUpload{
    height: 100px;
    line-height: 100px;
    margin-left: 16px;
    font-family: "Core Sans C";
    color: var(--blue75);
    font-size: 12px;
    font-weight: 600;
}
.Uploaded img{
    width: 200px;
}
.Image>div{
    width: 200px;
    height: 100px;
    overflow: hidden;
}
.Image>div.Close{
    transform: translateY(-100px);
    background-color: var(--blue25);
    display: none;
    justify-content: right;
    padding: 10px;
}
.Image:hover>div.Close{
    display: flex;
}
.CloseIcon{
    color: white;
}
.Uploaded{
    display: flex;    
    gap: 10px;
}
.Uploaded>div{
    border-radius: 8px;
    min-width: 200px;
    height: 100px;
    overflow: hidden;
    border: 1px solid var(--blue25);
}

.UploadedCont{
    height: 100px;
    overflow-y: hidden;
    overflow-x: auto;
}

.Label{
    min-width: 200px;
    border: 1px solid var(--blue25);
}
.Label>label{
    display: block;
    width: 200px;
    height: 100px;
    line-height: 100px;
    border-radius: 4px;
    border: 1px solid var(--blue25);
    background-color:#4A678B;
    color: white;
    font-family: "Core Sans C";
    font-size: 12px;
    font-weight: 600;
}
.Cont>*{
    display: block;
}
.Wrapper{
    overflow: hidden;
    width: 100%;
    box-sizing: border-box;
}
.Cont{
    display: flex;
    gap: 10px;
}
.DropDown{
    position: relative;
    display: inline-block;
}
.DropBtn>a{
    text-decoration: none;
    font-size: 30px;
}
.DropDown-Cont{
    position: absolute;
    right: 0;
    transform: translate(5px, -2px);
    z-index: 1;
    overflow-y: hidden;
}
.DropDown>.DropDown-Cont>div>ul{
    position: relative;
    right: 0;
    margin: 3px;
    border: 1px solid var(--blue25);
    box-shadow: -1px 0px 3px var(--blue75);
    background-color: white;
    display: block;
    transform: translateY(-0px);
    display: block;
    background-color: aqua;
}
.DropDown>.DropDown-Cont>div{
    transition: 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    position: relative;
    top: -250px;
    border: 1px solid transparent;
}
.DropDown>.DropBtn:hover + .DropDown-Cont>div, .DropDown-Cont>div:hover{
    top:0px
}

.DropDown ul>li{
    width: 210px;
    list-style: none;
    background-color: white;
}
.DropDown li:nth-child(n+2){
    border-top: 1px solid var(--blue25);    
}
.DropDown li>a{
    transition: 1s;
    display: block;
    padding: 10px 40px;
    color: var(--blue75);
    font-family: "Core Sans C";
    text-decoration: none;
    font-size: 16px;
    font-weight: 600;
}
.DropDown li>a:hover{
    background-color: var(--lightblue);
    padding: 10px 60px;
}
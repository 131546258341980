.Template {
	padding: 20px;
}
.Top {
	display: flex;
	justify-content: space-between;
	margin-bottom: 8px;
}
.type {
	font-family: "Core Sans C";
	font-style: normal;
	font-weight: 600;
	font-size: 14px;
	line-height: 14px;
	color: var(--electricblue);
	margin-bottom: 4px;
}
.name {
	font-family: "Core Sans C";
	font-style: normal;
	font-weight: 600;
	font-size: 20px;
	line-height: 24px;
	color: var(--blue);
}
.content {
	font-family: "Core Sans C";
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 24px;
	color: var(--blue75);
}
.dropdown {
	transform: translateY(-10px);
}

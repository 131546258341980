.Edit {
	display: flex;
	flex-direction: column;
	gap: 30px;
	> div:not(:last-child) {
		padding-bottom: 30px;
		border-bottom: 1px solid var(--blue25);
	}
}
.Top {
	display: flex;
	gap: 30px;
	> div {
		height: 150px;
		max-width: 300px;
		color: var(--blue75);
		display: flex;
		flex-direction: column;
		justify-content: center;
	}
}
.Logo {
	> img {
		max-width: 200px;
		max-height: 150px;
	}
}
.File {
	width: 150px;
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	> input {
		display: none;
	}
	> label {
		background-color: var(--electricblue);
		padding: 10px;
		color: white;
		display: flex;
		justify-content: center;
		font-weight: 500;
	}
}

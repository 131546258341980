.Modal{
    width: 500px;
    height: 500px;
    padding: 30px 32px;
    border-top: 1px solid var(--blue25);
    display: flex;
    flex-direction: column;
    gap: 40px;
    >.Foot{
        margin-top: auto;
    }
}
.Equipment{
    display: flex;
    flex-direction: column;
    gap: 16px;
    >div{
        display: flex;
        color: var(--blue75);
        >div:nth-child(1){
            font-weight: 600;
           width: 200px;
           color: var(--blue);
        }
    }
}
.KnowledgeBase {
  width: 100%;
  display: flex;

  .FiltersSide {
    flex: 30%;
  }

  .KBMain {
    padding: 30px;
    flex: 65%;

    .Lists {
      margin-top: 30px;
      display: grid;
      gap: 20px;
    }
  }

  .AISide {
    flex: 35%;
  }
}

.Milestones{
    background-color: var(--blue);
    height: 100vh;
    overflow-y: auto;
    padding: 40px;
    display: flex;
    flex-direction: column;
}
.ProgressValue{
    color: var(--electricblue);
}
.Title>div{
    font-family:"Core Sans C";
    line-height: 133%;
}
.Title{
    color: white;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;
}
.Progess{
    margin-bottom: 40px;
}
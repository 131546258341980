.StatsGroup{
    margin-bottom: 40px;
}
.Title{
    font-family: 'Core Sans C';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px; 
    color: var(--electricblue);
    padding-bottom: 16px;
}
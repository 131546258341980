.TemplateSingleList {
  border: 1px var(--blue25) solid;
  border-radius: 8px;
  padding: 20px;

  .Category {
    color: var(--electricblue);
    font-weight: 600;
    margin-bottom: 5px;
  }

  .Row {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .Name {
      font-size: 20px;
      font-weight: 600;
      line-height: 24px;
      color: var(--blue);
    }

    >svg{
      cursor: pointer;
    }
  }
  .Description {
    color: var(--blue);
    font-size: 14px;
    line-height: 19.6px;
    text-align: justify;
  }
}

.Dashboard{
    display: flex;
    width: 100%;
}
.DBLeft{
    flex-basis: 65%;
}
.DBLeftHead{
    display: flex;
    justify-content: space-between;
    margin: 40px 40px 0px 40px;
}
.DBLeftHead>div>h2{
    font-family: 'Roboto';
    font-size: 32px;
    font-weight: 600;
    text-transform: capitalize;
    line-height: 40px;  
    color: var(--blue);
}
.DBLeftTop{
    display: flex;
    padding: 24px 40px;
    height: 605px;
    overflow:auto;
    justify-content: space-between;
}
.DBLeftTop>div{
flex-basis: 47.5%;
}
.DBRight{
    flex-basis: 35%;
    background-color: var(--blue);
    padding: 40px;
    height:fit-content;
    min-height: 100vh;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
}
.DBRight>h3{
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    color: var(--electricblue);    
}
.TaskItems{
    margin-top: 16px;
}
.TaskItemDetails{
    display: flex;
    width: 100%;
    justify-content: space-between;
}
.TaskProgressTrack{
    height: 5px;
    background-color: var(--blue25);
    border-radius: 2000px;
}
.DBPieChart{
    border: 1px solid var(--blue25);
    border-radius: 10px;
    width: 100%;
    padding: 40px;
}

.EngineersTasks{
    border: 1px solid var(--blue25);
    border-radius: 10px;
    overflow: hidden;
}
.TaskCarousel{
    padding: 40px 40px 0px 40px;
    height: 560px;
}
.ETHead{
    display: flex;
    justify-content: space-between;
    margin-bottom: 40px;
}
.EngrsTaskList>div{
    margin-bottom: 10px;
}
.carousel li{
width: 10vw;
}
.DBLeftRow{
    margin: 0px 40px;
    border: 1px solid var(--blue25);
    border-radius: 10px;
    padding: 40px;
}
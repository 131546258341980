.Expandable{
    height: 0px;
    box-shadow: 0px 0px 3px var(--blue25);
    transition: 1s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    overflow: hidden;
    position: relative;
    z-index: 1;
    background-color: white;
}
.Cont{
    transition: 1s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}
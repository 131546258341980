.Milestone{
    margin-bottom: 16px;
    display: flex;
    padding: 16px;
    background: linear-gradient(0deg, rgba(78, 203, 113, 0.1), rgba(78, 203, 113, 0.1)), #FFFFFF;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    border-radius: 4px;}
.Title{
    font-family: 'Core Sans C';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: var(--blue50);    
}
.count{
    background-color: var(--blue50);
    display: inline-block;
    font-size: 12px;
    padding: 0px 5px;
    margin-left: 10px;
    color: var(--white);
}
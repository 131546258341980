.Time{
    display: flex;    
    justify-content: center;
    border: 1px solid white;
}
.Separator{
    font-weight: 600;
    transform: translateY(5px);
}
.Time input{
    border: none !important;
}
.Time input:focus{
    outline: 0;
}
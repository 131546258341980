.Modal{
    height: 600px;
    width: 800px;
    overflow-y: auto;
    overflow-x: hidden;
    border-top: 1px solid var(--blue25);
    >*{
        width: 100%;
        height: 100%;
    }
}
.Cont{
    margin:0px 33px;
}
.Jobs {
	width: 100%;
	padding: 40px;
}
.Record {
	margin-top: 40px;
}
.status {
	font-family: "Core Sans C";
	font-size: 12px;
	font-weight: 600;
	display: inline;
	padding: 5px 20px;
}
.initial::before {
	content: " ";
	width: 6px;
	height: 6px;
	border-radius: 50%;
	display: inline-block;
	margin-right: 5px;
	background-color: var(--electricblue);
}
.initial {
	color: var(--electricblue);
	background-color: var(--electricblue25);
}
.requirements {
	color: var(--blue);
	background-color: var(--blue25);
}
.risk {
	color: var(--red);
	background-color: var(--red10);
}

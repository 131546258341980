.AddEngineerModel {
	width: 500px;
	padding: 30px 32px;
	border-top: 1px solid var(--blue25);
}
.FormControl {
	font-style: normal;
	font-weight: 600;
	font-size: 16px;
	line-height: 16px;
	align-items: center;
	text-transform: capitalize;
	color: var(--blue75);
}
.FormControl > div {
	margin-bottom: 10px;
}
.FormControl input {
	padding: 10px;
	font-weight: 600;
	font-size: 16px;
	line-height: 16px;
	width: 100%;
	border: 1px solid var(--blue25);
	color: var(--blue);
}
.FormActions {
	display: flex;
	justify-content: right;
}
.FormActions > div {
	min-width: 150px;
}

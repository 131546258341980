.Page {
	width: 100%;
	padding: 40px;
}
.TDPage {
	width: 100%;
	height: 100vh !important;
	overflow: auto;
	display: flex;
}
.TDLeft {
	flex-basis: 60%;
	border-right: 1px solid var(--blue75);
}
.TDRight {
	flex-basis: 40%;
	padding: 40px;
	height: 100vh;
	overflow-y: auto;
	position: sticky;
	top: 0;
	background-color: var(--blue);
}
.Readonly {
	position: fixed;
	z-index: 0;
	opacity: 0.4;
	width: 100%;
	height: 100vh;
}
.TDRight .Title {
	padding-bottom: 24px;
	font-style: normal;
	font-weight: 600;
	font-size: 24px;
	line-height: 32px;
	color: var(--electricblue);
}
.TDLeft {
	padding: 50px;
}
.GeneralTaskInfo > div {
	color: var(--blue);
	display: flex;
}
.GeneralTaskInfo > div > div {
	line-height: 150%;
	margin-bottom: 20px;
}
.GeneralTaskInfo > div > div:nth-child(1) {
	font-weight: 600;
	font-size: 16px;
	flex-basis: 200px;
}
.TaskDetailsBtns {
	display: flex;
}
.TaskDetailsBtns > div {
	margin-right: 20px;
}
.ExpenseList {
	list-style: none;
	li {
		display: flex;
		justify-content: space-between;
		width: 100%;
	}
}
.amountTitle {
	font-size: 16px;
	font-weight: 600;
	color: var(--blue);
}
.amountValue {
	font-size: 46px;
	font-weight: 600;
	color: var(--electricblue);
}
.expenseFoot {
	font-size: 16px;
	font-weight: 600;
	div {
		display: flex;
		margin-top: 10px;
		color: var(--electricblue);
		div:nth-child(1) {
			color: var(--blue);
			width: 150px;
		}
	}
}
.controls {
	display: flex;
	gap: 50px;
	padding: 20px 0px;
	div {
		flex-basis: 200px;
	}
}
.Approval {
	padding: 40px 0px;
	.Title {
		font-size: 20px;
		color: var(--electricblue);
		font-weight: 600;
		margin-bottom: 10px;
	}
	.Details {
		display: flex;
		font-size: 16px;
		justify-content: space-between;
		font-weight: 600;
		color: var(--blue75);
		margin-bottom: 10px;
	}
	.Remark {
		font-weight: 600;
		font-size: 16px;
		color: var(--blue75);
	}
}
.Approve {
	background-color: var(--electricblue15);
	padding: 20px;
	border: 1px solid var(--electricblue75);
	.Title {
		font-size: 18px;
		font-weight: 600;
		color: var(--electricblue);
		margin-bottom: 10px;
	}
	.Details {
		display: flex;
		justify-content: space-between;
	}
}

.Decline {
	background-color: var(--red15);
	padding: 20px;
	border: 1px solid var(--red40);
	.Title {
		font-size: 18px;
		font-weight: 600;
		color: var(--red);
		margin-bottom: 10px;
	}
	.Details {
		display: flex;
		justify-content: space-between;
	}
}
.Approvals {
	display: flex;
	flex-direction: column;
	gap: 20px;
	padding: 20px 0px;
}
.ExpensesList {
	display: flex;
	flex-direction: column;
	gap: 20px;
}
.receiptThumb {
	max-height: 100px;
	overflow: hidden;
}
.ExpandedView {
	width: 800px;
	height: 80vh;
	.ReceiptObject > * {
		max-width: 800px;
	}
}

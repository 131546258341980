.info,
.danger,
.warning,
.active {
	display: block;
	border: 1px solid var(--blue10);
	border-radius: 4px;
	display: flex;
	overflow: hidden;
	cursor: pointer;
}
.active {
	background: var(--lightblue);
}
.warning {
	background: var(--yellow10);
}
.danger {
	background-color: var(--red10);
}
.band {
	width: 10px;
}

.info .band,
.active .band {
	background-color: var(--electricblue);
}
.danger .band {
	background-color: var(--red);
}
.warning .band {
	background-color: var(--yellow);
}

.content {
	width: 100%;
	padding: 16px 30px;
	display: flex;
	justify-content: space-between;
}
.text {
	font-family: "Core Sans C";
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 16px;
	color: var(--blue);
	margin-bottom: 8px;
}
.info .text > span {
	color: var(--electricblue);
}
.active .text > span {
	color: var(--blue);
	font-weight: 600;
}

.danger .text > span {
	color: var(--red);
}
.warning .text > span {
	color: var(--yellow);
}

.time {
	font-family: "Core Sans C";
	font-style: normal;
	font-weight: 400;
	font-size: 12px;
	line-height: 14px;
	color: var(--blue75);
}
.icon {
	width: 40px;
	height: 40px;
	text-align: center;
	border-radius: 50%;
	line-height: 40px;
}
.danger .icon {
	background-color: var(--red40);
}
.warning .icon {
	background-color: var(--yellow40);
}
.danger .icon svg {
	transform: translate(1px, 3px);
}
.warning .icon svg {
	transform: translate(-1px, -3px);
}

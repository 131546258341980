.Contact {
  display: flex;
  min-height: 100vh;

  .ContactLeft {
    background: url(~/src/Assets/Images/image10.png);
    flex-basis: 42%;
    background-repeat: no-repeat;
    background-position: right 0px;
    padding: 50px 80px 80px 80px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .ContactLeftContent {
      .title {
        color: var(--white);
        font-size: 32px;
        font-weight: bold;
        margin-bottom: 20px;
      }
      .text {
        color: var(--white);
        margin-bottom: 10px;
      }
      .key {
        color: var(--electricblue);
        font-weight: bold;
      }
    }
  }

  .ContactRight {
    flex-basis: 58%;
    background-color: white;
    padding: 40px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .StepsCount {
      color: var(--electricblue);
      font-weight: bold;
    }

    .Form {
      margin: 0 100px;

      .FormTitle {
        font-size: 32px;
        font-weight: 600;
        color: var(--blue);
        margin-bottom: 20px;
      }
    }

    .Buttons {
      display: flex;
      align-items: center;
      justify-content: end;
      gap: 10px;

      > div {
        width: 100px;
      }
    }
  }

  .CompletedRight {
    flex-basis: 58%;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;

    .CompletedBox {
      background-color: var(--backgrounds-green-subtler);
      border: 1px solid var(--border-color-default);
      border-radius: 4px;
      padding: 20px;

      .Title {
        font-size: 24px;
        font-weight: 600;
        line-height: 32px;
        color: var(--green-dark);
        margin-bottom: 10px;
      }

      .Details {
        font-size: 14px;
        font-weight: 400;
        line-height: 19.6px;
        color: var(--blue);
      }
    }

    .VerifyingBox {
      background-color: var(--electricblue5);
      border: 1px solid var(--border-color-default);
      border-radius: 4px;
      padding: 30px;

      .Title {
        font-size: 30px;
        font-weight: 600;
        line-height: 32px;
        margin-bottom: 10px;
        color: var(--blue);
      }
    }
  }
}

.TaskCompany{
    display: flex;
}
.TaskCompany>h5{
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    color: var(--blue75);
}
.TaskCompany::before{
    content: "C";
    text-align: center;
    line-height: 24px;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    display: block;
    color: #FFFFFF;
    background-color: var(--blue);    
    margin-right: 8px;
}
